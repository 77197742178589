<template>
  <v-chip small text-color="white" :color="getColor">
    {{ getLabel }}
  </v-chip>
</template>

<script>
  export default {
    name: "RequestStatusLabel",
    props: {
      status: String
    },
    data: () => ({}),
    computed: {
      getColor() {
        return this.$helpers.getRequestStatusColor(this.status);
      },
      getLabel() {
        return this.$t(`leave_request_status.${this.status}`);
      }
    },
    methods: {}
  };
</script>

<style scoped></style>
